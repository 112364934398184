import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../classes/user';
import { DataService } from '../../services/data.service'
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../services/app.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private dataService: DataService, private toastr: ToastrService, private router: Router, public appService: AppService, private dateTimeFormatService: DateTimeFormatService
    ) { }


  ngOnInit(): void {
    this.logout();
  }

  logout() {
    var referrer = document.referrer;
    console.log(referrer);
    this.dataService.logoutDOMO().subscribe(
      (res: any) => {
        localStorage.removeItem('cultureSettings');
        localStorage.removeItem('unitSystem');
        localStorage.removeItem('cultureOptionSelected');
        localStorage.removeItem('centerName');
        localStorage.removeItem('centerId');
        localStorage.removeItem('userName');
        localStorage.removeItem('token');
        localStorage.removeItem('auth0token');

        this.appService.RunEnumsList = null;
        this.appService.userCenters = [];
        this.appService.UserProfile = null;
        //this.toastr.success('Welcome to ELSO Registry', 'Logout success.');
        //this.router.navigateByUrl('/login')
      },
      err => {
        console.log(err);
      }
    );
  }
}
