import { Component, AfterViewInit, ViewChild, NgZone } from '@angular/core';
//import { MdePopoverTrigger } from '@material-extended/mde';
import { ForTooltipComponent } from '../tooltip/for-tooltip.component';
import * as Highcharts from 'highcharts';
import { DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { PaginatorService } from '../../services/paginator.service';
import { LoadingService } from '../../services/loading.service';
import { BehaviorSubject, } from 'rxjs';
import { Router } from '@angular/router';




declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  component = ForTooltipComponent;
  public query: string;
  public elsoGridSortBy: any;
  public elsoGridOrderBy: any;
  //@ViewChild(MdePopoverTrigger, { static: true }) popoverDiscountTrigger: MdePopoverTrigger;


  //popoverDiscountDestroy(): void {
  //  this.popoverDiscountTrigger.destroyPopover();
  //  // YES!, destroy popoverDiscountTrigger
  //}


  public overAllRunsOptions: any = {

    chart: {
      renderTo: 'container',
      type: 'column',
      options2d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 100
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      labelFormatter: function () {
        var name = '';
        if (this.index < 3) {
          name = 'Runs';
        }
        else if (this.index < 6) {
          name = 'Pulmonary';
        }
        else if (this.index < 9) {
          name = 'Cardiac';
        }
        else if (this.index < 12) {
          name = 'ECPR';
        }

        return name + ' - ' + this.name;
      }
    },
    title: {
      text: 'Runs per year (includes incomplete runs)'
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: 'Number of runs'
      }
    },
    tooltip: {
      formatter: function () {
        var name = '';
        if (this.series.stackKey == 'columnrun') {
          name = 'Runs';
        }
        else if (this.series.stackKey == 'columnpulmonary') {
          name = 'Pulmonary';
        }
        else if (this.series.stackKey == 'columncardiac') {
          name = 'Cardiac';
        }
        else if (this.series.stackKey == 'columnecpr') {
          name = 'ECPR';
        }
        return this.x + ' - ' + name + '(' + this.point.stackTotal + ') - ' + this.series.name + '(' + this.y + ')';
      }
    },

    plotOptions: {
      column: {
        stacking: 'normal',
        depth: 20
      }
    },

    series: [
      {
        name: 'Adults',
        data: [],
        stack: 'run',
        color: '#FFCD00'
      },
      {
        name: 'Pediatrics',
        data: [],
        stack: 'run',
        color: '#FF9A00'
      },
      {
        name: 'Neonatals',
        data: [],
        stack: 'run',
        color: '#FF6000'
      },
      {
        name: 'Adults',
        data: [],
        stack: 'pulmonary',
        color: '#6AF350'
      },
      {
        name: 'Pediatrics',
        data: [],
        stack: 'pulmonary',
        color: '#33E610'
      },
      {
        name: 'Neonatals',
        data: [],
        stack: 'pulmonary',
        color: '#249E0A'
      },
      {
        name: 'Adults',
        data: [],
        stack: 'cardiac',
        color: '#8182FF'
      },
      {
        name: 'Pediatrics',
        data: [],
        stack: 'cardiac',
        color: '#3434FF'
      },
      {
        name: 'Neonatals',
        data: [],
        stack: 'cardiac',
        color: '#0000E6'
      },
      {
        name: 'Adults',
        data: [],
        stack: 'ecpr',
        color: '#FFB5B5'
      },
      {
        name: 'Pediatrics',
        data: [],
        stack: 'ecpr',
        color: '#FF6868'
      },
      {
        name: 'Neonatals',
        data: [],
        stack: 'ecpr',
        color: '#FF0000'
      }
    ]
  };


  public volumeOptions: any = {

    chart: {
      type: 'boxplot',
      events: {
        load: function load() {

          var xAxis = this.xAxis[0];
          var yAxis = this.yAxis[0];
          var colors = ['red', 'green', 'blue', 'magenta'];

          for (var i = 0; i < this.series[0].data.length; i++) {
            var point = this.series[0].data[i];
            var yt = yAxis.toPixels(point.high);
            var x = xAxis.toPixels(point.x);

            this.renderer.path().attr({
              d: 'M ' + (x - 5) + ' ' + yt + ' L ' + (x + 5) + ' ' + yt,
              stroke: colors[0],
              'stroke-width': 3
            }).add();

            var yb = yAxis.toPixels(point.low);

            this.renderer.path().attr({
              d: 'M ' + (x - 5) + ' ' + yb + ' L ' + (x + 5) + ' ' + yb,
              stroke: colors[1],
              'stroke-width': 3
            }).add();

            var yBoxBottom = yAxis.toPixels(point.q1);
            var xBoxLeft = point.series.group.translateX + point.shapeArgs.x;
            var xBoxRight = xBoxLeft + point.shapeArgs.width;

            this.renderer.path().attr({
              d: 'M ' + xBoxLeft + ' ' + yBoxBottom + ' L ' + xBoxRight + ' ' + yBoxBottom,
              stroke: colors[2],
              'stroke-width': 2,
              zIndex: 99
            }).add();

            var yBoxTop = yAxis.toPixels(point.q3);
            this.renderer.path().attr({
              d: 'M ' + xBoxLeft + ' ' + yBoxTop + ' L ' + xBoxRight + ' ' + yBoxTop,
              stroke: colors[3],
              'stroke-width': 2,
              zIndex: 199
            }).add();

            var yBoxMedian = yAxis.toPixels(point.median);
            //console.log(point);
            //point.whiskers.hide();
          }
        }
      }

    },

    title: {
      text: ''
    },

    legend: {
      enabled: true
    },

    xAxis: {
      categories: ['North America', 'Europe', 'Asia Pacific', 'Latin America', 'SWAAC'],
      title: {
        text: '      '
      }
    },

    yAxis: {
      title: {
        text: 'Annual Center Volume'
      },
      tickInterval: 5,
      min: 0,
      max: 75,/////////
      plotLines: [{
        color: 'red',
        width: 2,
        value: this.getCenterVolume()//[]
      }]
    },
    plotOptions: {
      boxplot: {
        fillColor: 'white',
        lineWidth: 2,
        medianColor: 'black',
        medianWidth: 3,
        stemColor: 'black',
        stemDashStyle: 'solid',
        stemWidth: 1,
        whiskerColor: 'black',
        whiskerLength: '20%',
        whiskerWidth: 3
      }
    },
    series: [{
      name: 'Region Runs',
      type: 'boxplot',
      showInLegend: false,

      color: 'black',
      data: this.getVolumeOptions(),//[],
      tooltip: {
        headerFormat: '<em>{point.key}</em><br/>'
      }
    }]

  };

  public getVolumeOptions() {
    if (this.volumeOptions) {
      return this.volumeOptions.series[0]['data'];
    } else {
      return [];
    }
  }
  public getCenterVolume() {
    //console.log(this.centerVolume);
    return this.centerVolume;
  }

  constructor(private router: Router, private dataService: DataService, public appService: AppService, public paginatorService: PaginatorService, private zone: NgZone) {
  }
 
  
  public overallRunsChart: any;
  public volumeChildrenChart: any;
  public volumeAdultChart: any;
  public runCounts: any;
  public volumeData: any;
  public centerVolume: any;
  public boxPlotYearIndex: any;
  chartType = 0;
  incompleteRuns;


  public titleBox;
  public maxBox;
  graphOptionSelect(graphType) {
    if (this.overallRunsChart) {
      //console.log(this.overallRunsChart);
      this.overallRunsChart.destroy();
    }
    if (graphType == 1 || graphType == 2) {

      if (this.boxPlotYearIndex == null) {
        for (var i = 0; i < this.runCounts.length; i++) {
          
          if (this.runCounts[i].Year == (new Date()).getFullYear() - 1) {
            this.boxPlotYearIndex = i;
          }
        }
      }

      if (graphType == 1) {
        this.titleBox = "Neonatal & Pediatric";
        this.maxBox = 55;

        if (this.boxPlotYearIndex != undefined && this.boxPlotYearIndex != null)
        this.centerVolume = this.runCounts[this.boxPlotYearIndex].Run_NeonatalCount + this.runCounts[this.boxPlotYearIndex].Run_PediatricCount;
      }

      if (graphType == 2) {
        this.titleBox = "Adults";
        this.maxBox = 100;

        if (this.boxPlotYearIndex != undefined && this.boxPlotYearIndex != null)
        this.centerVolume = this.runCounts[this.boxPlotYearIndex].Run_AdultCount;
      }

      //if (this.volumeData == null) {
        this.dataService.get('Home/GetVolumeGraph', 'Age', graphType).subscribe(
          (res: any) => {
            this.volumeData = res.data;
            //console.log(res.data);
            this.volumeOptions.title.text = this.titleBox;
            this.volumeOptions.yAxis.max = this.maxBox;
            this.volumeOptions.yAxis.plotLines[0].value = this.centerVolume;
            //this.volumeOptions.series[0]['data'] = this.volumeData;

            var data = [];
            for (var i = 0; i < this.volumeData.length; i++) {
              data.push([this.volumeData[i].minChart, this.volumeData[i].q1, this.volumeData[i].median, this.volumeData[i].q3, this.volumeData[i].maxChart]);
            }

            this.volumeOptions.series = [{
              name: 'Region Runs',
              type: 'boxplot',
              showInLegend: false,
              color: 'black',
              data: data,//[],
              tooltip: {
                headerFormat: '<em>{point.key}</em><br/>'
              }
            }];

            this.volumeOptions.yAxis = {
              title: {
                text: 'Annual Center Volume'
              },
              tickInterval: 5,
              min: 0,
              max: this.maxBox,
              plotLines: [{
                color: 'red',
                width: 2,
                value: this.centerVolume
              }]
            };

            //console.log(this.volumeOptions.Series);
            //this.overallRunsChart = Highcharts.chart('container', this.volumeOptions);
            this.zone.runOutsideAngular(() => this.overallRunsChart = Highcharts.chart('container', this.volumeOptions));

          },
          err => {
            console.log(err);
          }
        )
      //}

      
    } else {
      this.centerVolume = [];
      //this.overallRunsChart = Highcharts.chart('container', this.overAllRunsOptions);
      this.zone.runOutsideAngular(() => this.overallRunsChart = Highcharts.chart('container', this.overAllRunsOptions));

    }
  }

  uploadFile = (files) => {

    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('uploadXMLFile', fileToUpload, fileToUpload.name);

    this.dataService.create('XMLImport/UploadXMLFile', formData).subscribe(
      (res: any) => {
        //console.log(res);
      },
      err => {
        console.log(err);
      }
    )
  }
  

  ngOnInit() {
    console.log(this.appService.TestXMLLogin);
    console.log(this.appService.registryVersion);
    //console.log(this.appService.UserProfile.UserRoles);
    //console.log(this.appService.userCenters);
    //reset paginator settings
    this.paginatorService.lowValue = 0;
    this.paginatorService.highValue = 5;

    this.initData();

    if (this.appService.isUserInRole('Exam Not Finished')) {
      alert("You must successfully complete the Data Entry Exam to add or edit Registry data");
    }

    //if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true || this.appService.isUserInRole('Multi Center User') == true) {
    //  //get centers for user, show dd list
    //  this.dataService.getall('Users/GetCentersForUser').subscribe(
    //    (res: any) => {
    //      console.log('aaaa');
    //      this.appService.userCenters = res.data;
    //     // console.log(this.appService.userCenters);

    //      this.initData();
    //    },
    //    err => {
    //      console.log(err);
    //    }
    //  );
    //} else {
    //  this.initData();

    //}

    //test sample for datadump download
    //this.dataService.getFile('DataDump/DataDumpEquipmentMasterList', null, null).subscribe(
    //  (res: File) => {
    //    var a = document.createElement("a");
    //    a.href = URL.createObjectURL(res);
    //    a.download = 'export.xlsx';
    //    // start download
    //    a.click();
    //    console.log(res);
    //  },
    //  err => {
    //    console.log(err);
    //  }
    //);
    //}, 0);
   

    
  }

  initData() {
   // console.log(this.appService.userCenters);
    this.appService.Run = null;
    this.appService.Patient = null;

    this.appService.AllDateValidations = [];
    this.appService.MinMaxHardSoftValues = [];

    //setTimeout(() => { //Timeout is needed because of loadingService console error

    this.dataService.get('Home/GetChartData', 'Id', this.appService.UserProfile.CenterId).subscribe(
      (res: any) => {
        this.runCounts = res.data;
        //console.log(this.runCounts);
        var run_adults = [this.runCounts.length];
        var run_pediatrics = [this.runCounts.length];
        var run_neonatals = [this.runCounts.length];

        for (var i = 0; i < this.runCounts.length; i++) {
          run_adults[i] = this.runCounts[i].Run_AdultCount;
          run_pediatrics[i] = this.runCounts[i].Run_PediatricCount;
          run_neonatals[i] = this.runCounts[i].Run_NeonatalCount;
        }
        var pulmonary_adults = [this.runCounts.length];
        var pulmonary_pediatrics = [this.runCounts.length];
        var pulmonary_neonatals = [this.runCounts.length];

        for (var i = 0; i < this.runCounts.length; i++) {
          pulmonary_adults[i] = this.runCounts[i].Pulmonary_AdultCount;
          pulmonary_pediatrics[i] = this.runCounts[i].Pulmonary_PediatricCount;
          pulmonary_neonatals[i] = this.runCounts[i].Pulmonary_NeonatalCount;
        }
        //-------------------------------------------------------------------------------------
        var cardiac_adults = [this.runCounts.length];
        var cardiac_pediatrics = [this.runCounts.length];
        var cardiac_neonatals = [this.runCounts.length];

        for (var i = 0; i < this.runCounts.length; i++) {
          cardiac_adults[i] = this.runCounts[i].Cardiac_AdultCount;
          cardiac_pediatrics[i] = this.runCounts[i].Cardiac_PediatricCount;
          cardiac_neonatals[i] = this.runCounts[i].Cardiac_NeonatalCount;
        }
        //-------------------------------------------------------------------------------------
        var ecpr_adults = [this.runCounts.length];
        var ecpr_pediatrics = [this.runCounts.length];
        var ecpr_neonatals = [this.runCounts.length];

        for (var i = 0; i < this.runCounts.length; i++) {
          ecpr_adults[i] = this.runCounts[i].ECPR_AdultCount;
          ecpr_pediatrics[i] = this.runCounts[i].ECPR_PediatricCount;
          ecpr_neonatals[i] = this.runCounts[i].ECPR_NeonatalCount;
        }

        this.overAllRunsOptions.series[0]['data'] = run_adults;
        this.overAllRunsOptions.series[1]['data'] = run_pediatrics;
        this.overAllRunsOptions.series[2]['data'] = run_neonatals;

        this.overAllRunsOptions.series[3]['data'] = pulmonary_adults;
        this.overAllRunsOptions.series[4]['data'] = pulmonary_pediatrics;
        this.overAllRunsOptions.series[5]['data'] = pulmonary_neonatals;

        this.overAllRunsOptions.series[6]['data'] = cardiac_adults;
        this.overAllRunsOptions.series[7]['data'] = cardiac_pediatrics;
        this.overAllRunsOptions.series[8]['data'] = cardiac_neonatals;

        this.overAllRunsOptions.series[9]['data'] = ecpr_adults;
        this.overAllRunsOptions.series[10]['data'] = ecpr_pediatrics;
        this.overAllRunsOptions.series[11]['data'] = ecpr_neonatals;

        this.overAllRunsOptions.xAxis.categories = this.appService.lodashMapBy(this.runCounts, 'Year').map(String);

        this.zone.runOutsideAngular(() => this.overallRunsChart = Highcharts.chart('container', this.overAllRunsOptions));
      },
      err => {
        console.log(err);
      }
    )


    this.getIncompleteRuns();
  }

  getIncompleteRuns() {
    this.dataService.get('Home/GetIncompleteRunsForCenter', null, null).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.incompleteRuns = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }
  deletePatient(patientId: string) {

    this.dataService.delete('Patients/DeletePatient', 'PatientId', patientId)?.subscribe(
      (res: any) => {
        //reload incomplete runs
        this.getIncompleteRuns();
      },
      err => {
        console.log(err);
      }
    );
  }

  deleteRun(run: any) {
    //console.log(run);

    var deleteRun = true;

    var tempRuns = this.appService.lodashFilterBy(this.incompleteRuns, 'PatientId', run.PatientId);
    if (tempRuns.length == 1) {
      deleteRun = confirm("This is the last run for patient. If you delete this run, patient will also be deleted. Please confirm.");
    }

    if (deleteRun == true) {
      this.dataService.delete('Runs/DeleteRun', 'RunId', run.RunId)?.subscribe(
        (res: any) => {
          //reload incomplete runs
          this.getIncompleteRuns();
        },
        err => {
          console.log(err);
        }
      );
    }

  }

  loadRun(runId: string) {
    //setting loading service to on, to show laoding for lazy loaded run module

    //Used when data service is not called
    //this.ls.isLoading$ = new BehaviorSubject<boolean>(true);
    //this.router.navigateByUrl('/run/runinfo/' + runId);
    /////////////////

    //setTimeout(() => {//Whole part commented on 11/15/2024 because patient and run are loaded on RunInfo section. Need to test more if this comment will cause some issue

    //    this.dataService.get('Runs/GetRun', 'RunId', runId).subscribe(
    //      (res: any) => {

    //        this.appService.Patient = res.data.Patient;
    //        this.appService.Run = res.data.Run;
    //        this.appService.AllDateValidations = res.data.DateValidationList;
    //        this.appService.MinMaxHardSoftValues = res.data.MinMaxHardSoftValidationList;

            this.router.navigateByUrl('/run/runinfo/' + runId);
    //      },
    //      err => {
    //        console.log(err);
    //      }
    //    )
    //}, 0);
  }

  loadPatient(patientId: string) {
    //setTimeout(() => {//Whole part commented on 11/15/2024 because patient is loaded on PatientInfo section. Need to test more if this comment will cause some issue

    //  this.dataService.get('Patients/GetPatient', 'PatientId', patientId).subscribe(
    //    (res: any) => {

    //      this.appService.Patient = res.data;

          //this.ls.isLoading = new BehaviorSubject<boolean>(true);
          this.router.navigateByUrl('/patient/patientinfo/' + patientId);
    //    },
    //    err => {
    //      console.log(err);
    //    }
    //  )
    //}, 0);
  }
}
