////import { Injectable } from '@angular/core';
////import {
////  HttpRequest,
////  HttpHandler,
////  HttpInterceptor,
////  HttpResponse
////} from '@angular/common/http';
////import { finalize } from 'rxjs/operators';
////import { LoadingService } from '../services/loading.service';


////@Injectable()
////export class LoadingInterceptor implements HttpInterceptor {
////  private totalRequests = 0;

////  constructor(private loadingService: LoadingService) { }

////  intercept(request: HttpRequest<any>, next: HttpHandler) {
////    this.totalRequests++;
////    this.loadingService.setLoading(true);

////    return next.handle(request).pipe(
////      finalize(() => {
////        this.totalRequests--;
////        if (this.totalRequests === 0) {
////          this.loadingService.setLoading(false);
////        }
////      })
////    );
////  }
////}



import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { AppService } from "../services/app.service";
import { LoadingService } from "../services/loading.service";


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoadingService, private toastr: ToastrService, public appService: AppService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);

    }
    //console.log(i, this.requests.length);
    this.appService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.appService.isLoading.next(true);
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
              if (event.body) {
                if (event.body.message && event.body.message != "") {
                  //console.log(event.body);
                  if (event.body.status != 406) {
                    this.toastr.success(event.body.message);
                  } else {
                    this.toastr.error(event.body.message);
                  }
                }
              }
              //console.log(event);
            }
          },
          err => {
            this.removeRequest(req); observer.error(err); this.toastr.error(err.error.message); },
          () => { this.removeRequest(req); observer.complete(); });
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
